import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})
export class VentaService {
  httpOptions: { headers: HttpHeaders };
  urls!: string;
  token!: any;

  constructor(private http: HttpClient) {
    this.token = localStorage.getItem('token');
    this.httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.token
      })
    };
  }

  getProductos(
    keyword: any,
    cat: any,
    page: number,
    perPage: number
  ): Observable<any> {
    this.urls = path + '/prods';

    let params = new HttpParams();
    params = params.append('nombre', keyword);
    params = params.append('categoria', cat);
    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  getClientes(keyword: any, page: number, perPage: number): Observable<any> {
    this.urls = path + '/clie';

    let params = new HttpParams();
    params = params.append('nombre', keyword);
    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  getVentas(
    serach: string,
    recibo: string,
    keyword: any,
    nombre_completo: any,
    page: number,
    perPage: number
  ): Observable<any> {
    this.urls = path + '/venta';

    let params = new HttpParams();
    params = params.append('serach', serach);
    params = params.append('recibo', recibo);
    params = params.append('fecha', keyword);
    params = params.append('nombre_completo', nombre_completo);

    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  getCategorias(page: number, perPage: number): Observable<any> {
    this.urls = path + '/cates';

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  create(
    fecha: any,
    empleado_id: string,
    cliente_id: any,
    nombre_completo: any,
    numero_mesa: any,
    articulos: any,
    tipo_pago: any,
    checked: any,
    celular: any,
    direccion: any
    // pagos: any
  ): Observable<any> {
    this.urls = path + '/register-venta';

    var datoaEnviar = {
      fecha: fecha,
      user_id: empleado_id,
      cliente_id: cliente_id,
      nombre_completo: nombre_completo,
      numero_mesa: numero_mesa,
      articulos: articulos,
      tipo_pago: tipo_pago,
      tipo_pedido: checked,
      celular: celular,
      direccion: direccion
      // pagos: pagos
    };
    return this.http.post(this.urls, datoaEnviar, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  update(
    id: any,
    // fecha: any,
    empleado_id: string,
    cliente_id: any,
    articulos: any,
    tipo_pago: any,
    nombre_completo: any,
    checked: any,
    celular: any,
    direccion: any
    // pagos: any
  ): Observable<any> {
    this.urls = path + '/update-venta/' + id;
    console.log(tipo_pago);

    var datoaEnviar = {
      // fecha: fecha,
      user_id: empleado_id,
      cliente_id: cliente_id,
      articulos: articulos,
      tipo_pago: tipo_pago,
      nombre_completo: nombre_completo,
      tipo_pedido: checked,
      celular: celular,
      direccion: direccion
      // pagos: pagos
    };
    return this.http.post(this.urls, datoaEnviar, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  updateVentaEstado(estado: string, id: any): Observable<any> {
    this.urls = path + '/update-venta-estado/' + estado + '/' + id;

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true
    });
  }

  delete(id: string): Observable<any> {
    this.urls = path + '/delete-venta/' + id;
    return this.http.delete(this.urls, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getRecepcion(keyword: any, page: number, perPage: number): Observable<any> {
    this.urls = path + '/recepcion';

    let params = new HttpParams();
    params = params.append('fecha', keyword);
    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  updateVenta(id: any): Observable<any> {
    this.urls = path + '/update-rep/' + id;

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true
    });
  }

  updateVentaHelado(id: any): Observable<any> {
    this.urls = path + '/update-rep-helado/' + id;

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true
    });
  }

  createpedido(
    fecha: any,
    empleado_id: string,
    cliente_id: any,
    articulos: any,
    lat: any,
    lng: any,
    tipo_pago: any,
    nombre_completo: any,
    celular: any,
    direccion: any,
    link: any,
    pagos: any
  ): Observable<any> {
    // this.urls = path + '/register-pedido';
    this.urls = path + '/crear-pedido';
    var datoaEnviar = {
      fecha: fecha,
      fecha_entrega: fecha,
      user_id: empleado_id,
      cliente_id: cliente_id,
      articulos: articulos,
      lat: lat,
      lng: lng,
      tipo_pago: tipo_pago,
      nombre_completo: nombre_completo,
      celular: celular,
      direccion: direccion,
      link: link,
      pagos: pagos
    };
    return this.http.post(this.urls, datoaEnviar, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  updatepedido(
    id: number,
    fecha: any,
    empleado_id: string,
    cliente_id: any,
    articulos: any,
    lat: any,
    lng: any,
    tipo_pago: any,
    nombre_completo: any,
    celular: any,
    direccion: any,
    link: any,
    pagos: any
  ): Observable<any> {
    this.urls = path + '/update-pedido/' + id;

    var datoaEnviar = {
      fecha: fecha,
      fecha_entrega: fecha,
      user_id: empleado_id,
      cliente_id: cliente_id,
      articulos: articulos,
      lat: lat,
      lng: lng,
      tipo_pago: tipo_pago,
      nombre_completo: nombre_completo,
      celular: celular,
      direccion: direccion,
      link: link,
      pagos: pagos
    };
    return this.http.post(this.urls, datoaEnviar, this.httpOptions).pipe(
      tap((data: any) => {
        return of(data);
      }),
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getPedidos(
    serach: string,
    recibo: string,
    keyword: any,
    estado: any,
    nombre_completo: any,
    page: number,
    perPage: number
  ): Observable<any> {
    this.urls = path + '/index-pedidos';

    let params = new HttpParams();
    params = params.append('serach', serach);
    params = params.append('recibo', recibo);
    params = params.append('fecha', keyword);
    params = params.append('estado', estado);
    params = params.append('nombre_completo', nombre_completo);
    params = params.append('page', page);
    params = params.append('perPage', perPage);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  getPedidosD(id: any): Observable<any> {
    this.urls = path + '/tracking-order-state/' + id + '';

    let params = new HttpParams();
    // params = params.append('id', id);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true
      // params: params
    });
  }

  getJugos(cliente: string, recibo: string, keyword: any): Observable<any> {
    this.urls = path + '/index-jugos';

    let params = new HttpParams();
    params = params.append('cliente', cliente);
    params = params.append('recibo', recibo);
    params = params.append('fecha', keyword);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }

  getHelados(cliente: string, recibo: string, keyword: any): Observable<any> {
    this.urls = path + '/index-helados';

    let params = new HttpParams();
    params = params.append('cliente', cliente);
    params = params.append('recibo', recibo);
    params = params.append('fecha', keyword);

    return this.http.get<any>(`${this.urls}`, {
      headers: this.httpOptions.headers,
      reportProgress: true,
      params: params
    });
  }
}
