// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiConfig: {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    headersFormData: {},
    // path: 'http://127.0.0.1:8000/api'
    // path: 'http://api_rest_eaed.test/api'
    // path: 'https://apiamoresdulce.namnamapp.com/api',
    path: 'https://api-dulceambicion.namnamapp.com/api'

    // path:  'https://api.elamoresdulcebolivia.com/api'

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
